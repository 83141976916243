.testimonial-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center; 
  justify-content: center;
  background-color: #2D2D2D; 
  padding: 4em 4em;
  color: #fff;
  flex-wrap: wrap; 
}

.testimonial-content {
  max-width: 60%;
  position: relative;
  flex: 1 1 60%; 
  min-width: 300px; 
  text-align: center; 
  word-wrap: break-word;
  max-width: 800px;
}
.testimonial-text {
  font-size: 1.2rem;
  line-height: 1.6;
  text-align: center;
  margin: 0;
}

.testimonial-author {
  font-size: 18px;
  text-align: center;
  font-weight: bold;
  color: #6fcf97; 
  margin-top: 5px;
  position: relative;
}

.quote-start,
.quote-end {
  width: 24px; 
  height: 24px;
  position: absolute;
}

.quote-start {
  top: -20px;
  left: -30px;
}

.quote-end {
  right: 0;
  bottom: -10px; 
  left: 780px;
  top: 95%; 
  transform: translateY(-50%); 
}

.testimonial-illustration img {
  width: 70%;
  height: auto;
  flex: 1 1 30%; 
  min-width: 200px; 
}
@media (max-width: 1024px) {
  .testimonial-container {
    padding: 40px 60px;
  }

  .testimonial-content {
    max-width: 100%;
    margin-bottom: 20px;
  }

  .quote-start,
  .quote-end {
    position: static; 
    margin: 10px auto;
  }

  .testimonial-illustration img {
    max-width: 50%;
    margin: 0 auto;
  }
}

@media (max-width: 768px) {
  .testimonial-container {
    padding: 20px 30px;
  }

  .testimonial-text {
    font-size: 1rem; 
    line-height: 1.4;
  }

  .testimonial-author {
    font-size: 1.2rem;
  }
}

@media (max-width: 480px) {
  .testimonial-container {
    padding: 15px 20px;
  }

  .testimonial-text {
    font-size: 1rem;
    line-height: 1.3;
  }

  .testimonial-author {
    font-size: 1rem;
  }

  .testimonial-illustration img {
    max-width: 50%;
  }
}
