.news-section {
    padding: 30px;
    background-color: #f9f9f9;
    text-align: center;
    position: relative;
    width: 100%;
    min-height: 30vh;
    background-image: url('../../../assets/background/news-background.svg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.show-button {
    background-color: #4BA96E;
    border: none;
    color: white;
    font-size: 18px;
    height: 42px;
    font-weight: bold;
    cursor: pointer;
  }
  
.show-button:hover {
    background-color:  #5db87e!important;
    color: rgb(255, 255, 255) !important;
    border-color: #4BA96E !important;
  }
  

  .show {
    margin-top: 20px;
  }
  

@media (max-width: 768px) {
  .news-section {
    background-size: cover; 
    padding: 10px; 
    min-height: 100vh; 
  }
}

.news-card {
  position: relative;
  border-radius: 8px;
  overflow: hidden; 
}

h3 {
  margin-top: 10px;
  font-size: 1.2em; 
}

p {
  color: #6c6c6c;
  font-size: 0.9em; 
}

.show-button {
  background-color: #4BA96E;
  border: none;
  color: white;
  font-size: 18px;
  height: 42px;
  font-weight: bold;
  cursor: pointer;
  margin-top: 20px;
}

@media (max-width: 768px) {
  .show-button {
    font-size: 16px; 
    height: 38px;
  }

  .show {
    margin-bottom: 20px;
  }
}
