 .player-section {
    background-image: url('../../assets/background/player-with-pic.svg');  
    background-size: contain; 
    background-position: center;
    background-repeat: no-repeat;
    height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    padding: 0px 180px;
   
  }
  
  @media (max-width: 768px) {
    .player-section {
      background-image: url('../../assets/background/player-with-pic.svg');  
      height: 50vh; 
      margin-right: 30px;
    }
  
    .youtube-player {
      max-width: 100%;
    }
  }
  