.app-header {
    display: flex;
    align-items: center;
    background-color: #ffffff;
    position: relative;
    padding: 10px 80px;
    height: 64px; 
  }
  
  .logo {
    height: 4vh;
  }
  
  .menu {
    flex: 3;
    display: flex;
    justify-content: flex-end;
    background-color: transparent; 
  }
  
  .menu .ant-menu-item {
    color: #0F172A; 
    font-weight: 500;
    border: none; 
  }
  
  .contact-menu-item {
    background-color: #6fcf97; 
    color: #333; 
    border-radius: 5px; 
  }
  
  .ant-menu-item-selected {
    background-color: #ffffff !important;
    color: #63E6A7 !important;
  }

  .menu .ant-menu-item:hover {
    color: #333 !important;
  }

  /* .desktop-menu {
    display: block; 
  } */
  
  .mobile-menu-button {
    display: none; 
    
  } 

  .desktop-menu .ant-menu-item {
    font-size: 16px; 
  }
  
  @media (max-width: 768px) {
    .app-header {
      padding: 5px 115px;
    }
    .desktop-menu {
      display: none; 
      color: #333;
    }
    .header-container {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-left: 90px;
      
    }
    .mobile-menu-button {
      display: block; 
    }
  }

  

