@import url('https://fonts.googleapis.com/css2?family=Saira:wght@400;700&display=swap');

.orion-section {
  position: relative; 
  background: #2D2D2D;
  color: #e6fff2;
  font-family: Arial, sans-serif;
  padding: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 60vh;
  overflow: hidden;
}

.orion-section::before {
  content: ''; 
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, #2D2D2D 42%, rgba(0, 0, 0, 0) 60%),
              url('../../../assets/background/orionhub.svg') 260% center / contain no-repeat;
  z-index: 1; 
  pointer-events: none;
}

.content-events {
  position: relative; 
  z-index: 2;
  max-width: 600px;
  display: flex;
  flex-direction: column; 
  align-items: flex-start; 
  text-align: left; 
  max-width: 580px; 
  padding: 5px;
}

.content-events h1 {
  font-family: 'Saira', sans-serif;
  font-size: 48px;
  font-weight: bold;
  color: #84E094;
  margin-bottom: 20px; 
}

.content-events .details {
  display: flex;
  flex-direction: column; 
  gap: 15px; 
}

.details-b {
  margin-top: 20px;
}

.details p {
  color: #E6E6E6;
  font-size: 18px;
  display: flex;
  align-items: center;
  margin: 0;
}

.details img {
  width: 24px; 
  height: 24px;
  margin-right: 10px; 
}

@media (max-width: 968px) {
  .orion-section {
    flex-direction: column; 
    height: auto; 
    padding: 30px;
  }

  .orion-section::before {
    background: none;
  }

  .content-events h1 {
    font-size: 36px; 
  }

  .details p {
    font-size: 16px; 
  }
}

@media (max-width: 480px) {
  .content-events h1 {
    font-size: 28px; 
  }

  .details img {
    width: 20px; 
    height: 20px; 
  }

  .details p {
    font-size: 14px;
  }
}
