.tech-section {
  background-color: #8EF4A0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 16px;
  padding: 30px;
}

.tech-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 30px;
}

.tech-icon {
  margin-bottom: 5px;
  display: block;
}

.tech-title, .tech-description {
  margin: 0;
  padding: 0;
  text-align: left;
}

@media (max-width: 768px) {
  .tech-section {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .tech-item {
    display: flex;
    align-items: center;
    flex-direction: row;
    padding: 30px;
  }
  
  .tech-icon {
    margin-right: 16px;
  }
}

@media (max-width: 480px) {
  .tech-section {
    grid-template-columns: 1fr;
  }

  .tech-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 30px;
  }
  
  .tech-icon {
    margin-right: 16px;
  }
}
