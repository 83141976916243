.service-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 2px solid #6fcf97;
    margin: 0 auto;
  }
  .main {
  background-image: url('../../../assets/background/services-background.svg'); 
  background-size: cover; 
  background-position: center; 
  background-repeat: no-repeat;
  padding-bottom: 20px;
}
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .text-container-s {
    display: inline-block;
    text-align: center;
    word-wrap: break-word;
    max-width: 800px;
  }
  
  .divider-s {
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 4px solid #84E094;
    margin: 20px 0;
    width: 10rem;
  }
  

  .text {
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0.2px;
    line-height: 1.5;
    text-align: center;
  }

  @media (max-width: 768px) {
    .service-icon {
      width: 80px;
      height: 80px;
    }
    .container {
      padding: 20px;
    }
    .text-container-s {
      padding: 10px;
    }
    .text {
      font-size: 1.5rem;
    }
  }

  @media (max-width: 480px) {
    .service-icon {
      width: 60px;
      height: 60px;
    }
    .container {
      padding: 10px;
    }
    .text-container-s {
      padding: 5px;
    }
    .text {
      font-size: 1.2rem;
    }
  }