.hero-section {
  position: relative;
  width: 100%;
  height: 100vh; 
  background-image: url('../../../assets/background/section-background.svg'); 
  background-size: cover; 
  background-position: center; 
  background-repeat: no-repeat;
  display: flex; 
  justify-content: center; 
  align-items: center; 
}

.hero-overlay {
  position: absolute; 
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.hero-content {
  position: relative; 
  z-index: 2; 
  color: white; 
  text-align: center;
  padding: 20px;
}

.hero-content h1 {
  font-size: 50px;
  margin-bottom: 20px;
}

.hero-content p {
  font-size: 28px;
  margin-bottom: 30px;
  max-width: 65rem;
  word-wrap: break-word;
}

@media (max-width: 768px) {
  .hero-content h1 {
    font-size: 3rem;
  }

  .hero-content p {
    font-size: 1.5rem;
  }
}

@media (max-width: 480px) {
  .hero-content h1  {
    font-size: 2rem;
  }
  .hero-content p{
    font-size: 1.2rem;
  }
}

