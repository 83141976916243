.footer {
    background-color: #2D2D2D;
    color: #fff;
    padding: 20px;
    text-align: center;
    bottom: 0;
    width: 100%;
    box-sizing: border-box;
  }
  
  .footer .content {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .footer p {
    margin: 0;
    margin-right: 20px;
    font-size: 28px;
    font-weight: 700;
  }
  
  .footer .highlight {
    color: #7DFFA0; 
    font-weight: bold;
  }
 
  .copyright {
    display: flex;
    align-items: center; 
    gap: 10px; 
  }
  
  .copyright img {
    width: 100px;
    height: 28px;
  }
  
  .footer-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #f1f1f1;
  }
  
  .social-media-icons {
    display: flex;
    gap: 15px;
  }
  
  .social-media-icons img {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
  
  .social-media-icons a {
    display: inline-block;
  }
  
  .copyright {
    display: flex;
    align-items: center;
  }
  
  @media (max-width: 768px) {
    .footer-bottom {
      flex-direction: column;
      text-align: center;
    }
  
    .copyright {
      margin-bottom: 10px;
      flex-direction: column;
    }
 
    .social-media-icons {
      justify-content: center;
    }
  }
  
  @media (max-width: 480px) {
    .footer-bottom {
      flex-direction: column;
      text-align: center;
    }
  
    .copyright {
      margin-bottom: 10px;
      flex-direction: column;
    }
  
    .social-media-icons {
      justify-content: center;
      gap: 10px; 
    }

    .social-media-icons img {
      width: 20px;
      height: 20px;
    }
  }
  