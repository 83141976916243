.button-shared {
    background-color: white;
    border: none;
    color: #000;
    font-size: 18px;
    height: 42px;
    font-weight: bold;
    cursor: pointer;
  }
  
.button-shared:hover {
    background-color:  #4BA96E !important;
    color: rgb(255, 255, 255) !important;
    border-color: #4BA96E !important;
  }
  