.divider {
    border-top: 4px solid #84E094;
    margin: 20px 0;
    width: 20rem;
  }
  
  .section {
    display: flex;
    flex-direction: column; 
    align-items: center;
    padding: 40px;
    background-color: #f8f8f8; 
  }
  
  .text-container {
    flex: 0.5;
    padding-right: 20px;
    text-align: left;
    max-width: 100%;
  }
  
  .title {
    font-size: 3em;
    font-weight: 400;
    color: #333;
    margin-bottom: 10px;
  }
  
  .paragraph {
    font-size: 22px;
    color: #555;
    line-height: 1.5;
  }
  
  .link {
    font-size: 1.2em;
    color: #84E094;
    text-decoration: none;
    margin-top: 10px;
    display: inline-block;
  }
  
  .div-verde {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100px;
    background-color: #84E094;
  }
  
  .video-container {
    flex: 0.5;
    text-align: right;
    margin-top: 20px; 
    max-width: 100%;
  }
  
  @media (min-width: 768px) {
    .section {
      flex-direction: row; 
    }
  }

  @media (max-width: 768px) {
    .title {
      font-size: 3.5rem;
    }
  
    .paragraph {
      font-size: 1.5rem;
    }
  }
  
  @media (max-width: 480px) {
    .title {
      font-size: 2rem;
    }
  
    .paragraph {
      font-size: 1.2rem;
    }
  }
  