.image-container {
    position: relative;
  }
  
  .category-tag {
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: #2DCA73;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 12px;
    font-weight: bold;
  }
  
  .news-card {
    width: 450px; 
    background-color: transparent;
    border: none;
    box-shadow: none;
  }
  
  
  .news-card-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  .news-card h3, .news-card p {
    text-align: left;
    margin-top: 0;
    margin-bottom: 0;
  }

  
  